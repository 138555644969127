import React from 'react';

import './style.scss';

import bureauLogoDark from '../images/bureau-logo-dark.png';

const Header = ({ siteTitle }) => (
	<section className="hero heroBackgroundImage is-fullheight">
		<div className="hero-body">
			<div className="container center">
				<article className="media">
					<div className="media-content">
						<div className="content">
							<img className="bureau-logo" src={bureauLogoDark} alt="The Bureau of Digital Production"/>
							<h1 className="is-size-4 has-text-white has-text-centered hero-copy">
								Experience Design / Technology / Strategy
							</h1>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
);

export default Header;
