import React from 'react';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { StaticQuery, graphql } from 'gatsby';
import './style.scss';

import bureauLogoDark from '../images/bureau-logo-dark.png';
const Footer = () => (
	<StaticQuery
		query={graphql`
			query SocialQuery {
				site {
					siteMetadata {
						twitter
						instagram
						linkedin
					}
				}
			}
		`}
		render={data => (
			<footer className="footer has-background-black">
				<div className="columns">
					<div className="column"><img className="bureau-logo" src={bureauLogoDark} alt="The Bureau of Digital Production"/></div>
					<div className="column has-text-white has-text-centered is-size-7 footer-disclaimer">&copy; 2019 TheBureau, LTD. All Rights Reserved.</div>
					<div className="column has-text-white has-text-centered social-icons">
						<span className="icon">
							<a href={data.site.siteMetadata.instagram}>
								<FaInstagram size="fa-2x" color="white" />
							</a>
						</span>
						<span className="icon">
							<a href={data.site.siteMetadata.twitter}>
								<FaTwitter size="fa-2x" color="white" />
							</a>
						</span>
						<span className="icon">
							<a href={data.site.siteMetadata.linkedin}>
								<FaLinkedin size="fa-2x" color="white" />
							</a>
						</span>
					</div>
				</div>
			</footer>
		)}
	/>
);

export default Footer;
