import React from 'react';

import './style.scss';


const WhoWeAre = ({ siteTitle }) => (
	<section className="hero is-fullheight">
		<div className="hero-body">
			<div className="container center">
				<article className="media">
					<div className="media-content">
						<div className="content">
							<h2 className="is-size-1 has-text-black has-text-centered who-header">
								Who We Are
							</h2>
							<p className="subtitle has-text-black is-size-5 has-text-centered who-copy">
							We are a digital production, and technology solution partner. We help brands succeed by turning thoughtful strategies into engaging multi-channel experiences and digital products.
							</p>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
);

export default WhoWeAre;
